<template>
    <v-container grid-list-xl>
        <v-layout wrap>
            <v-flex xs6>
                <v-img :src="require('@/assets/images/logoIFluks.jpg')" aspect-ratio="1" max-width="250" max-height="130" class="pa-0 ma-0 align-center" alt="iFluks" />
            </v-flex>
            <v-flex xs6>
                <v-img :src="require('@/assets/images/logoRed.png')" aspect-ratio="1" max-width="200" max-height="104" class="pa-0 ma-0 align-center" alt="INCHARGE" />
            </v-flex>

            <v-container v-if="data" class="pa-2 py-0 my-1 mt-0" fluid>
                <Point :point="data.point" :id="id" title="Sessão de carregamento:"></Point>
            </v-container>

            <v-container v-if="plug" class="py-0 ma-0 mb-1" fluid>
                <v-card :class="colorPanel()" dark>
                    <v-card-text class="white--text py-0">
                        <v-flex xs12>
                            <div class="headline">
                                <v-icon dark v-if="plug.status == 1">power</v-icon>
                                <v-icon dark v-else-if="plug.status == 0">done_outline</v-icon>
                                <v-icon dark v-else>cloud_off</v-icon>
                                Plug {{ plug.number }}
                            </div>
                            {{ textStatus() }}
                        </v-flex>
                        <v-flex v-if="plug.status == 0" class="text-center" xs12>
                            <v-flex v-if="statusPayment == null" class="text-center" xs12>
                                <div class="display-1 mb-6">Vamos lá?</div>
                                <div class="font-weight-bold mb-6">
                                    Para prosseguirmos com o caregamento é necessário o pagamento de R${{ data.price }} por {{ data.time }} minutos de carregamento.
                                </div>
                                <div class="text-h5 my-12">Antes de iniciarmos preciso que conecte a bike ; )</div>
                                <div class="font-weight-bold mb-6">Clique no botão abaixo e você será levado a uma interface segura de pagamento.</div>
                                <ButtonPayBike :price="data.price" :chargerName="id" :plugNumber="plug.number" :userId="$store.state.user.ID" />
                            </v-flex>
                            <v-flex v-else-if="statusPayment == 0" class="text-center" xs12>
                                <div class="display-1 mb-6">: ( Desculpe</div>
                                <div class="font-weight-bold mb-6">Houve algum problema no processo de pagamento, caso o erro continue, avise-nos clicando no botão abaixo.</div>
                                <v-btn @click="reportProblem('Problema no pagamento')" class="mb-2" color="secondary" v-on="on" :disabled="block">
                                    <v-icon left>sync_problem</v-icon>Reportar problema
                                </v-btn>
                            </v-flex>
                            <v-flex v-else-if="statusPayment == 1 && plug.payment != 'OK'" class="text-center" xs12>
                                <div class="display-1 mb-6">; ) Pagamento OK</div>
                                <div class="font-weight-bold mb-6">Aguarde que já iniciaremos o carregamento...</div>
                            </v-flex>
                            <v-flex v-else-if="statusPayment == 1" class="text-center" xs12>
                                <div class="display-1 mb-6">: ( Ops</div>
                                <div class="font-weight-bold mb-6">Houve algum problema para iniciar o carregamento, aguarde estou tentando novamente.</div>
                            </v-flex>
                        </v-flex>
                        <v-progress-linear v-if="block" indeterminate />
                    </v-card-text>
                </v-card>
            </v-container>
            <v-progress-linear v-else indeterminate />
        </v-layout>
    </v-container>
</template>

<script>
import Point from "@/components/shared/Point";
import ButtonPayBike from "./ButtonPayBike";

export default {
    components: {
        Point,
        ButtonPayBike,
    },

    data() {
        return {
            id: null,
            plug: null,
            plugNumber: null,
            data: null,
            block: false,
            timer: null,
            statusPayment: null,
        };
    },

    mounted() {
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
        }
        if (this.$route.params.plugNumber) {
            this.plugNumber = this.$route.params.plugNumber;
        }

        if (!this.$store.getters.isLogged) {
            this.backMainPage();
        } else {
            if (this.$route.params.statusPayment) {
                this.statusPayment = this.$route.params.statusPayment == "success" ? 1 : 0;
                this.block = true;
            }
            this.update();
            this.timer = setInterval(this.update, 5000);
        }
    },

    methods: {
        backMainPage() {
            this.$router.replace({ path: `/ebike/${this.id}` });
        },
        update() {
            this.$http
                .get("api/v2/ebike/" + this.id, { continuous: true })
                .then((result) => {
                    this.data = result;
                    if (this.data.price > 0) {
                        this.data.plugs.forEach((plug) => {
                            if (plug.number == this.plugNumber) {
                                this.plug = plug;
                                return;
                            }
                        });
                        if (this.plug.payment == "OK") {
                            this.recognize();
                        } else if (this.plug.status == 1) {
                            this.backMainPage();
                        }
                    } else {
                        this.backMainPage();
                    }

                    this.block = false;
                })
                .catch(() => {
                    this.data = null;
                });
        },
        recognize() {
            this.block = true;
            this.$http
                .post("api/v2/ebike/recognize", { charger: this.id, plug: this.plug.number })
                .then((result) => {
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Enviado com sucesso.");
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar, tente novamente.");
                });
        },
        reportProblem(message) {
            this.$http
                .post("api/v2/report-problem", { charger: this.id, message })
                .then((result) => {
                    this.backMainPage();
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Problema reportado com sucesso.");
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar.");
                });
        },
        textStatus() {
            return this.plug.status === 1 ? "Carregando" : this.plug.status === 0 ? "Disponível" : "Offline";
        },
        colorPanel() {
            return this.plug.status === 1 ? "error" : this.plug.status === 0 ? "success" : "grey";
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>